import axios from 'axios';
import {
  createContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { useUser } from '@auth0/nextjs-auth0/client';
import makeUrl from '~/services/utils/makeUrl';
import usePersistentStorageState from '~/hooks/usePersistantStorage';
import { CustomUserTraits, UserOrganization } from './type';
import { CountryCode } from '~/constants';

interface UserInfoApiResponse {
  first_name: string;
  last_name: string;
  email: string;
  scribe_usage_ms: number;
  plan: Plan;
  expiry: number;
  professional_identifier_number: string;
  phone: string;
  customer_id: string;
  claims: string[];
  tos_accepted: boolean;
  medical_specialty: string;
  enterprise: string | null;
  userOrganization: UserOrganization | null;
  trial: boolean;
  plan_after_trial: Plan;
  plan_after_trial_expiry: number | null;
  country?: CountryCode;
  province?: string;
  userTraits?: CustomUserTraits | null;
}

export interface User {
  firstName: string;
  lastName: string;
  email: string;
  userId: string;
  claims: string[];
  expiry: number;
  scribeUsageMs: number;
  medicalSpecialty: string;
  customerId: string;
  userOrganization: UserOrganization;
  plan: Plan;
  trial: boolean;
  planAfterTrial: Plan;
  country?: CountryCode;
}

interface AuthContext {
  userId: string;
  user: User | undefined;
  isLoading: boolean;
  isAuthenticated: boolean;
  isPremium: boolean;
  isPro: boolean;
  trial: boolean;
  enterprise: string | null;
  isEnterprise: boolean;
  plan: Plan;
  planAfterTrial: Plan;
  planAfterTrialExpiry: number | null;
  refreshUser: () => void;
  userTraits?: CustomUserTraits | null;
}

export enum Plan {
  loading = 'loading',
  free = 'free',
  premium = 'premium',
  pro = 'pro',
}

export type UserRegionType = 'CA' | 'US';

export const PERSIST_USER_REGION = 'PERSIST_USER_REGION';

export const AuthContext = createContext<AuthContext>({
  userId: '',
  isLoading: true,
  user: undefined,
  isAuthenticated: false,
  isPremium: false,
  isPro: false,
  trial: false,
  enterprise: null,
  isEnterprise: false,
  plan: Plan.loading,
  planAfterTrial: Plan.loading,
  planAfterTrialExpiry: null,
  refreshUser: () => null,
  userTraits: null,
});

export const AuthContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userData, setUserData] = useState<UserInfoApiResponse | undefined>();
  const [loadingUserInfo, setFetchingUserInfo] = useState(false);
  const { user, isLoading } = useUser();
  const [forceRefetch, setForceRefetch] = useState(0);
  const [, addUserRegionToLocalStorage] =
    usePersistentStorageState(PERSIST_USER_REGION);

  // If the user object changes, make sure we store the correct region for them
  useEffect(() => {
    if (user && !isLoading) {
      const metadata = user?.['https://tali.ai/app_metadata'] as Record<
        string,
        string | Plan[] | boolean
      >;
      addUserRegionToLocalStorage(
        metadata.country === 'CA' || !metadata.country ? 'CA' : 'US',
      );
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  const userId = useMemo(() => {
    const metadata = user?.['https://tali.ai/app_metadata'] as Record<
      string,
      string | Plan[] | boolean
    >;
    return metadata?.userId as string;
  }, [user]);

  const getUserInfo = useCallback(async (userId: string) => {
    try {
      const url = makeUrl('/user/{USER_ID}', { USER_ID: userId });
      const config = {
        headers: {
          next_target: url,
          next_method: 'get',
        },
      };
      setFetchingUserInfo(true);
      const { data } = await axios.post('/api/fetch', {}, config);
      const userData = data.data as UserInfoApiResponse;

      userData.userTraits = {
        id: userId,
        email: userData.email,
        firstName: userData.first_name,
        lastName: userData.last_name,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        company: { name: userData.userOrganization as any },
        title: userData.medical_specialty,
        phone: userData.phone,
        country: userData.country,
        province: userData.province,
        professionalIdentifierNumber: userData.professional_identifier_number,
      };
      setUserData(userData);
      setFetchingUserInfo(false);
    } catch (e) {
      setFetchingUserInfo(false);
    } finally {
      setFetchingUserInfo(false);
    }
  }, []);

  const { userInfo, ...userState } = useMemo(() => {
    if (!userData || Object.keys(userData).length === 0) {
      return {
        isPremium: false,
        isPro: false,
        trial: false,
        enterprise: null,
        isEnterprise: false,
        plan: Plan.loading,
        planAfterTrial: Plan.loading,
        planAfterTrialExpiry: 0,
        userInfo: undefined,
        userTraits: null,
      };
    }
    const {
      first_name,
      last_name,
      email,
      claims,
      plan,
      scribe_usage_ms,
      expiry,
      medical_specialty,
      trial,
      plan_after_trial,
      plan_after_trial_expiry,
      enterprise,
      userOrganization,
      customer_id,
      userTraits,
      country,
    } = userData;
    const lPlanAfterTrial =
      plan_after_trial === Plan.pro
        ? Plan.pro
        : plan_after_trial === Plan.premium
        ? Plan.premium
        : Plan.free;

    return {
      isPremium: Boolean(
        plan_after_trial === Plan.premium || plan === Plan.premium,
      ),
      isPro: plan === Plan.pro,
      trial: trial,
      enterprise: enterprise,
      isEnterprise: Boolean(enterprise),
      plan: plan ?? Plan.free,
      planAfterTrial: lPlanAfterTrial,
      planAfterTrialExpiry: plan_after_trial_expiry ?? 0,
      userInfo: {
        firstName: first_name,
        lastName: last_name,
        email,
        userId,
        claims,
        scribeUsageMs: scribe_usage_ms,
        expiry,
        medicalSpecialty: medical_specialty,
        customerId: customer_id,
        userOrganization: userOrganization || UserOrganization.tali,
        plan,
        trial,
        country,
        planAfterTrial: lPlanAfterTrial,
      },
      userTraits,
    };
  }, [userData, userId]);

  useEffect(() => {
    if (!isLoading && userId) {
      setIsAuthenticated(true);
      getUserInfo(userId);
    }
  }, [userId, isLoading, getUserInfo, forceRefetch]);

  /**
   * Fetches the latest information from the server about the user
   */
  const refreshUser = useCallback(() => {
    setForceRefetch((val) => val + 1);
  }, [setForceRefetch]);
  /**
   * Forces a refresh of the user's premium status.
   */

  return (
    <AuthContext.Provider
      value={{
        userId,
        isAuthenticated,
        isLoading: isLoading || loadingUserInfo,
        user: userInfo,
        ...userState,
        refreshUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
