import clsx from 'clsx';
import styles from './Text.module.scss';

interface Props {
  title?: string;
  type?: 'light' | 'dark';
  size: 'h1' | 'sudoH1' | 'h2' | 'h3' | 'subH1' | 'p1' | 'miniTitle' | 'paragraph1' | 'p2' | 'paragraph2' | 'semiBoldParagraph2' | 'copyright' | 'semiTitle';
  isError?: boolean;
  textAlign?: 'start' | 'end' | 'center';
  paddingTop?: string;
  paddingBottom?: string;
  paddingLeft?: string;
  paddingRight?: string;
  containerStyle?: string;
  textStyle?: string;
  icon?: React.ReactNode;
  children?: React.ReactNode,
}

export default function Text(props: Props) {
  const { children, title: text, containerStyle, type, size, isError, textStyle, textAlign, paddingTop, paddingBottom, paddingLeft, paddingRight } = props;
  const isHeader = ['h1', 'sudoH1', 'h2', 'h3', 'subH1'].includes(size);
  let alignment = 'center';
  if (textAlign === 'start') {
    alignment = 'flex-start';
  } else if (textAlign === 'end') {
    alignment = 'flex-end';
  }

  const title = children || text;

  return (
    <div
      className={clsx(styles.container, containerStyle)}
      style={{
        paddingTop,
        paddingBottom,
        paddingLeft,
        paddingRight,
        justifyContent: alignment,
        textAlign,
      }}
    >
      {props.icon}
      {/* Display size of h1, h2, h3 and light or dark */}
      {isHeader ? (
        size === 'h1' ? (
          <h1
            className={clsx(
              {
                [styles.lightHeader]: type === 'light',
                [styles.darkHeader]: isHeader && type === 'dark',
              },
              styles.titleH1,
              {
                [styles.error]: isError,
              },
              textStyle
            )}
          >
            {title}
          </h1>
        ) : size === 'sudoH1' ? (
          <h2
            className={clsx(
              {
                [styles.lightHeader]: type === 'light',
                [styles.darkHeader]: isHeader && type === 'dark',
              },
              styles.titleH1,
              {
                [styles.error]: isError,
              },
              textStyle
            )}
          >
            {title}
          </h2>
        ) : size === 'h2' ? (
          <h2
            className={clsx(
              {
                [styles.lightHeader]: type === 'light',
                [styles.darkHeader]: isHeader && type === 'dark',
              },
              styles.titleH2,
              {
                [styles.error]: isError,
              },
              textStyle
            )}
          >
            {title}
          </h2>
        ) : size === 'h3' ? (
          <h3
            className={clsx(
              {
                [styles.lightHeader]: type === 'light',
                [styles.darkHeader]: isHeader && type === 'dark',
              },
              styles.titleH3,
              {
                [styles.error]: isError,
              },
              textStyle
            )}
          >
            {title}
          </h3>
        ) : (
          <h4
            className={clsx(
              {
                [styles.lightHeader]: type === 'light',
                [styles.darkHeader]: isHeader && type === 'dark',
              },
              styles.subH1,
              {
                [styles.error]: isError,
              },
              textStyle
            )}
          >
            {title}
          </h4>
        )
      ) : (
        <div
          className={clsx(
            // Set whether the background is light or dark and whether its a header or paragraph text
            {
              [styles.lightHeader]: isHeader && type === 'light',
              [styles.darkHeader]: isHeader && type === 'dark',
              [styles.lightParagraph]: !isHeader && type === 'light',
              [styles.darkParagraph]: !isHeader && type === 'dark',
            },
            // Set the size of the text
            {
              [styles.paragraph1]: size === 'paragraph1' || size === 'p1',
              [styles.paragraph2]: size === 'paragraph2' || size === 'p2',
              [styles.paragraph2SemiBold]: size === 'semiBoldParagraph2',
              [styles.copyright]: size === 'copyright',
              [styles.miniTitle]: size === 'miniTitle',
              [styles.semiTitle]: size === 'semiTitle',
            },
            {
              [styles.error]: isError,
            },
            textStyle
          )}
        >
          {title}
        </div>
      )}
    </div>
  );
}
