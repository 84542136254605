import { UserTraits } from '@segment/analytics-next';

export enum UserOrganization {
  well = 'WELL',
  tali = 'TALI',
}

export interface CustomUserTraits
  extends Pick<UserTraits, 'email' | 'name' | 'phone' | 'country'> {
  firstName: string;
  lastName: string;
  /**
   * User Id
   */
  id: string;
  province?: string;
  /**
   * The medical speciality chosen by the user in the profile completion step.
   */
  title: string;
  /**
   * String name of the the company ["tali", "well", "veradigm"]
   */
  company: { name?: UserOrganization };
  /**
   *   Identifier number (CPSO, NPI, etc.) the user entered into the name field when they signed up.
   */
  professionalIdentifierNumber?: string;
}
