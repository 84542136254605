import { AnalyticsBrowser } from '@segment/analytics-next';
import { usePathname } from 'next/navigation';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useAuthData } from '~/hooks/use-auth-data';
import {
  AnalyticsClickEvent,
  AnalyticsClickEventDetails,
  AnalyticsTrackEvent,
  AnalyticsType,
} from './type';
import packageJSON from '../../package.json';

export const AnalyticsContext = createContext<AnalyticsType>({
  trackClick: () => undefined,
  trackLogout: () => undefined,
  getAnonymousId: () => {
    return null;
  },
  segmentIsReady: undefined,
});

const platform = {
  name: 'website',
  version: packageJSON.version,
};

const MAIN_HOST = 'tali.ai';

function sanitizePathName(pathname: string): string {
  return pathname.replace(/-/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase());
}

function getPageName(pathname: string): {
  category: string | undefined;
  name: string | undefined;
} {
  if (pathname === '/') {
    return { category: undefined, name: 'Home' };
  } else {
    const parts = pathname.trim().split('/');
    if (parts?.length == 2) {
      return { category: undefined, name: sanitizePathName(parts[1]) };
    } else if (parts?.length > 2) {
      return {
        category: sanitizePathName(parts[1]),
        name: sanitizePathName(parts[2]),
      };
    } else {
      return { category: undefined, name: undefined };
    }
  }
}

function getScreen() {
  if (screen && window) {
    return {
      width: screen.width,
      height: screen.height,
      density: window.devicePixelRatio,
    };
  } else {
    return undefined;
  }
}

export const AnalyticsAuthenticatedProvider = ({
  children,
}: PropsWithChildren) => {
  const { userTraits, plan } = useAuthData();
  const [segmentIsReady, setSegmentIsReady] = useState<boolean | undefined>(
    undefined,
  );

  const currentPage = useRef<string | null>();

  const pathname = usePathname();
  // All trackers start after userTrait resolved, So segment client is refer to the userTraits.country
  const segmentClient = useMemo(() => {
    let writeKey: string;
    if (typeof window === 'undefined') {
      return undefined;
    }

    if (window.location.host !== MAIN_HOST) {
      writeKey = '9Opymjk3vUDfCULlWAfieubnrCffDgBv';
    } else {
      writeKey = process.env['NEXT_PUBLIC_SEGMENT_WRITE_KEY'] as string;
    }
    if (writeKey) {
      return AnalyticsBrowser.load({
        writeKey,
      });
    }
  }, []);

  const traits = useMemo(() => {
    if (userTraits) {
      return {
        ...userTraits,
        plan,
      };
    } else {
      return undefined;
    }
  }, [userTraits, plan]);

  const userId = useMemo(() => {
    return userTraits?.id ? userTraits.id : undefined;
  }, [userTraits]);

  useEffect(() => {
    (segmentClient as AnalyticsBrowser)
      .then(() => {
        setSegmentIsReady(true);
      })
      .catch(() => {
        setSegmentIsReady(false);
      });
    if (pathname && pathname !== currentPage.current) {
      const { category, name } = getPageName(pathname);
      segmentClient?.page(
        category,
        name,
        { platform },
        {
          userId,
          context: {
            screen: getScreen(),
            traits,
          },
        },
      );
      currentPage.current = pathname;
    }
  }, [pathname, segmentClient, userId, traits]);

  const trackClick = useCallback(
    (
      eventName: AnalyticsClickEvent,
      clickDetails: AnalyticsClickEventDetails,
    ) => {
      segmentClient?.track(
        eventName,
        { platform, clickDetails },
        {
          userId,
          context: {
            screen: getScreen(),
            traits,
          },
        },
      );
    },
    [segmentClient, traits, userId],
  );

  const trackLogout = useCallback(() => {
    segmentClient?.track(
      AnalyticsTrackEvent.USER_LOGGED_OUT,
      { platform },
      {
        userId,
        context: {
          screen: getScreen(),
          traits,
        },
      },
    );
    segmentClient?.reset();
  }, [segmentClient, traits, userId]);

  const getAnonymousId = useCallback(() => {
    return segmentClient?.instance?.user()?.anonymousId();
  }, [segmentClient]);

  return (
    <AnalyticsContext.Provider
      value={{
        trackClick,
        trackLogout,
        getAnonymousId,
        segmentIsReady,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};
