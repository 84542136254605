import { UserRegionType } from "~/contexts/authentication";
import { PERSIST_USER_REGION } from "~/contexts/authentication";

const defaultAPIVersion = 'v1';
enum ApiVersion {
  'v1' = '/api/v1',
}

const API_KEY = process.env.NEXT_PUBLIC_API_KEY || '';
const REGION_BASE_URL: Record<UserRegionType, string> = {
  CA: process.env.NEXT_PUBLIC_SERVER_URL || '',
  US: process.env.NEXT_PUBLIC_US_SERVER_URL || '',
};

export default function makeUrl(
  urlWithPlaceholders: string,
  substitutions: { [key: string]: string },
  options: {
    apiVersion?: ApiVersion;
    isNLP?: boolean;
    isAnalytics?: boolean;
    region?: UserRegionType;
  } = {}
) {
  let region: UserRegionType = 'CA';
  if (typeof window === 'undefined') {
    region = options.region || 'CA';
  } else {
    region = (window?.localStorage.getItem(PERSIST_USER_REGION) || 'CA') as UserRegionType;
  }

  const baseUrl = REGION_BASE_URL[region];
  const { apiVersion, isNLP, isAnalytics } = options;
  let url: string = urlWithPlaceholders.replace(
    /{(\w+)}/g,
    (placeholderWithDelimiters, placeholderWithoutDelimiters) =>
      substitutions.hasOwnProperty(placeholderWithoutDelimiters)
        ? substitutions[placeholderWithoutDelimiters]
        : placeholderWithDelimiters
  );

  if (isNLP) {
    if (apiVersion !== undefined) {
      url = baseUrl + `${apiVersion}` + url;
    } else {
      url = baseUrl + `${ApiVersion[defaultAPIVersion]}` + url;
    }
  } else if (isAnalytics) {
    url = `${baseUrl}` + url;
  } else {
    if (apiVersion !== undefined) {
      url = baseUrl + `${apiVersion}` + url;
    } else {
      url = baseUrl + `${ApiVersion[defaultAPIVersion]}` + url;
    }
  }

  return url;
}

export { API_KEY };