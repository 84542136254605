import { ID } from '@segment/analytics-next';

export enum AnalyticsTrackEvent {
  ACCOUNT_COMPLETED = 'Account Completed',
  USER_LOGGED_OUT = 'Logout',
}

export enum AnalyticsClickEvent {
  REGISTER_BUTTON_CLICKED = 'Register Button Clicked',
  PAYWALL_CLICKED = 'Paywall Clicked',
}

export enum AnalyticsClickEventName {
  LOGIN = 'login',
}

export enum AnalyticsClickEventLocation {
  HOMEPAGE = 'homepage',
}

export enum AnalyticsClickEventElementId {
  NAVBAR_REGISTER = 'navbar-register-button',
  HOMEPAGE_BUTTON = 'homepage-register-button',
}

export type AnalyticsClickEventDetails = {
  name?: AnalyticsClickEventName;
  location?: AnalyticsClickEventLocation;
  elementId?: AnalyticsClickEventElementId;
  elementText?: string;
};

export type AnalyticsType = {
  trackClick: (
    name: AnalyticsClickEvent,
    clickDetails: AnalyticsClickEventDetails,
  ) => void;
  trackLogout: () => void;
  segmentIsReady: boolean | undefined;
  getAnonymousId: () => ID;
};

export enum PlatformName {
  WEBSITE = 'website',
  WELL_SDK = 'wellSdk',
  EXTENSION = 'chromeExtension',
  DESKTOP = 'desktop',
  MOBILE = 'mobile',
}
export type PlatformType = {
  name: PlatformName;
  version?: string;
};
